<template>
    <b-modal
        id="confirm-modal-success"
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        centered
        v-model="formContext.showScheduleModal"
    >
        <h2>Cronograma da Campanha</h2>
        <h4 class="pb-2 text-secondary">{{ context.descriptionCampaignSelected }}</h4>
        <div class="d-flex align-items-center mb-1">
            <feather-icon icon="CheckIcon" size="20" class="text-custom-blue mr-1"/>
            <p class="font-14 m-0">
                Adesão é feita pela 
                <label class="text-custom-blue m-0 font-14">Empresa</label> 
                ou pelo 
                <label class="text-custom-blue m-0 font-14">SESI</label>
            </p>
        </div>
        <div class="d-flex align-items-center mb-1">
            <feather-icon icon="CheckIcon" size="20" class="text-custom-blue mr-1"/>
            <p class="font-14 m-0">
                Agendamento das aplicações é feito pela
                <label class="text-custom-blue m-0 font-14">Empresa</label> 
                ou pelo 
                <label class="text-custom-blue m-0 font-14">SESI</label>
            </p>
        </div>
        <div class="d-flex align-items-center mb-2">
            <feather-icon icon="CheckIcon" size="20" class="text-custom-blue mr-1"/>
            <p class="font-14 m-0">
                O ato vacinal é feito pelo
                <label class="text-custom-blue m-0 font-14">SESI</label>
            </p>
        </div>

        <b-alert variant="danger" :show="formContext.form.errorApi.error" class="mb-0">
            <div class="alert-body">
                <feather-icon icon="InfoIcon" class="mr-50"/>
                {{ formContext.form.errorApi.error }}
            </div>
        </b-alert>

        <div v-if="formContext.loadingSchedule" class="w-100 mt-5 d-flex flex-column align-items-center justify-content-center">
            <b-spinner variant="custom-blue" class="mb-1" label="Loading..."/>
        </div>

        <validation-observer v-else ref="lancamentoRules">
            <b-form>
                <h5>Adesão</h5>
                <b-row>
                  <b-col v-if="context.termSelected" xl="12" class="systemPatternBox mt-1 mb-1">
                    <h4 class="titleTerm">Termo de adesão</h4>
                    <div class="d-flex adhesionContractBellow" @click.once="redirectHelp()">
                      <div>
                        <feather-icon
                            size="12"
                            icon="HelpCircleIcon"
                            class="text-custom-blue mr-1"
                        />
                      </div>
                      <div class="adhesionContractBellowTitle">
                        Dúvidas sobre o Termo de Adesão? Consulte a nossa Ajuda e Suporte.
                      </div>
                    </div>

                    <div class= 'systemPatternBoxSubtitle'>
                      O arquivo deve estar no formato PDF e não poderá exceder 1MB.
                    </div>
                    <div
                        class="d-flex justify-content-center align-items-center text-left"
                    >

                      <b-button
                          id="download-custom-model"
                          class='customDownloadButtonResponsive'
                          variant='custom-blue'
                          @click.prevent="downloadAdhesionContract"
                      >
                        <div class="maxWidthAdhesionContractDescription">
                          <feather-icon
                              size="18"
                              icon="DownloadIcon"
                              class="mr-50"
                          />
                          <span class="align-middle">{{ context.termSelected[0].anexo.descricao }}</span>
                        </div>
                      </b-button>
                      <div class="ml-2">
                        <feather-icon
                            size="24"
                            icon="XIcon"
                            class="text-custom-blue pointer"
                            @click.once="context.termSelected = null;"
                        />
                      </div>
                    </div>
                  </b-col>
                  <b-col v-else xl="12" class="systemPatternBox mt-1 mb-1" >
                      <h4 class="titleTerm">Termo de adesão</h4>
                      <div class="d-flex adhesionContractBellow" @click.once="redirectHelp()">
                        <div>
                          <feather-icon
                              size="12"
                              icon="HelpCircleIcon"
                              class="text-custom-blue mr-1"
                          />
                        </div>
                        <div class="adhesionContractBellowTitle">
                          Dúvidas sobre o Termo de Adesão? Consulte a nossa Ajuda e Suporte.
                        </div>
                      </div>
                      <div class='systemPatternBoxSubtitle'>
                        O arquivo deve estar no formato PDF e não poderá exceder 1MB.
                      </div>

                      <div>
                        <validation-provider
                            #default="{ errors }"
                            name="file"
                            rules="mimes:application/pdf|size:1024|required"
                        >
                          <div
                              class="d-flex justify-content-center align-items-center text-left"
                          >
                            <div>
                              <b-form-file
                                  v-model="formContext.form.file"
                                  @input="requiredFile = false"
                                  placeholder="Nenhum arquivo selecionado"
                                  drop-placeholder="Pode soltar o arquivo aqui"
                                  accept="application/pdf"
                              />
                            </div>
                            <div v-if="formContext.form.file && !context.termSelected" class="ml-3 customXIconResponsive">
                              <feather-icon
                                  size="24"
                                  icon="XIcon"
                                  class="text-custom-blue pointer"
                                  @click.once="dropFileAndErrors"
                              />
                            </div>
                          </div>
                          <small v-if="!formContext.form.file && requiredFile && errors[0]" class="text-danger d-flex align-items-center justify-content-center text-center">
                            <feather-icon
                                size="14"
                                icon="AlertCircleIcon"
                                class="mr-1"
                            />
                            É obrigatório selecionar um documento.
                          </small>
                          <small v-if="formContext.form.file && errors[0]" class="text-danger">
                            <feather-icon
                                size="14"
                                icon="AlertCircleIcon"
                            />
                            O documento deve ser do tipo PDF e ter no máximo 1 megabyte
                          </small>
                        </validation-provider>
                      </div>
                  </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6" md="6" sm="12">
                        <label for="start-date-adesao">Data de início*</label>
                        <validation-provider
                            #default="{ errors }"
                            name="startDateAdesao"
                            rules="required"
                        >
                        <b-form-datepicker
                            id="start-date-adesao"
                            :hide-header="true"
                            v-model="formContext.form.startDateAdesao"
                            placeholder="00/00/0000"
                            :disabled="formContext.disabledFieldEdit.adhesionStartDate"
                            right
                            label-help="Selecione a data de início da adesão"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="pt-BR"
                        />
                        <small v-if="errors[0]" class="text-danger">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.adesao.data_inicio" class="text-danger">
                            {{ formContext.form.errorApi.adesao.data_inicio }}
                        </small>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" class="pl-0">
                        <label for="end-date-adesao">Data de término*</label>
                        <validation-provider
                            #default="{ errors }"
                            name="endDateAdesao"
                            rules="required"
                        >
                        <b-form-datepicker
                            id="end-date-adesao"
                            :hide-header="true"
                            v-model="formContext.form.endDateAdesao"
                            placeholder="00/00/0000"
                            :disabled="formContext.disabledFieldEdit.adhesionEndDate"
                            right
                            label-help="Selecione a data de término da adesão"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="pt-BR"
                        />
                        <small v-if="errors[0]" class="text-danger">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.adesao.data_termino" class="text-danger">
                            {{ formContext.form.errorApi.adesao.data_termino }}
                        </small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row class="mt-1 mb-2">
                    <b-col lg="6" md="6" sm="12">
                        <label for="start-hour-adesao">Horario de início*</label>
                        <validation-provider
                            #default="{ errors }"
                            name="startTimeAdesao"
                            rules="required"
                        >
                        <b-form-timepicker 
                            :hide-header="true"
                            v-model='formContext.form.startTimeAdesao' 
                            :disabled="formContext.disabledFieldEdit.adhesionStartDate"
                            locale='pt-BR' 
                            no-close-button
                            placeholder="08:00"
                        />
                        <small v-if="errors[0]" class="text-danger">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.adesao.hora_inicio" class="text-danger">
                            {{ formContext.form.errorApi.adesao.hora_inicio }}
                        </small>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" class="pl-0">
                        <label for="start-hour-adesao">Horario de término*</label>
                        <validation-provider
                            #default="{ errors }"
                            name="endtimeAdesao"
                            rules="required"
                        >
                        <b-form-timepicker 
                            :hide-header="true"
                            v-model='formContext.form.endTimeAdesao' 
                            :disabled="formContext.disabledFieldEdit.adhesionEndDate"
                            locale="pt-BR" 
                            no-close-button
                            placeholder="18:00"
                        />
                        <small v-if="errors[0]" class="text-danger">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.adesao.hora_termino" class="text-danger">
                            {{ formContext.form.errorApi.adesao.hora_termino }}
                        </small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <h5>Vacinação</h5>
                <b-row>
                    <b-col lg="6" md="6" sm="12">
                        <label for="start-date-vacinacao">Data de início*</label>
                         <validation-provider
                            #default="{ errors }"
                            name="startDateVacina"
                            rules="required"
                        >
                        <b-form-datepicker
                            id="start-date-vacinacao"
                            :hide-header="true"
                            v-model="formContext.form.startDateVacinacao"
                            :disabled="formContext.disabledFieldEdit.vaccinationStartDate"
                            placeholder="00/00/0000"
                            right
                            label-help="Selecione a data de início da vacinação"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="pt-BR"
                        />
                        <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.vacinacao.data_inicio" class="text-danger">
                            {{ formContext.form.errorApi.vacinacao.data_inicio }}
                        </small>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" class="pl-0">
                        <label for="end-date-vacinacao">Data de término*</label>
                         <validation-provider
                            #default="{ errors }"
                            name="endDateVacina"
                            rules="required"
                        >
                        <b-form-datepicker
                            id="end-date-vacinacao"
                            :hide-header="true"
                            v-model="formContext.form.endDateVacinacao"
                            placeholder="00/00/0000"
                            right
                            label-help="Selecione a data de término da vacinação"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="pt-BR"
                        />
                        <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.vacinacao.data_termino" class="text-danger">
                            {{ formContext.form.errorApi.vacinacao.data_termino }}
                        </small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col lg="6" md="6" sm="12">
                        <label for="start-hour-vacinacao">Horario de início*</label>
                         <validation-provider
                            #default="{ errors }"
                            name="startTimeVacina"
                            rules="required"
                        >
                        <b-form-timepicker 
                            :hide-header="true"
                            v-model='formContext.form.startTimeVacinacao' 
                            :disabled="formContext.disabledFieldEdit.vaccinationStartDate"
                            locale='pt-BR' 
                            no-close-button
                            placeholder="08:00"
                        />
                        <small v-if="errors[0]" class="text-danger">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.vacinacao.hora_inicio" class="text-danger">
                            {{ formContext.form.errorApi.vacinacao.hora_inicio }}
                        </small>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" class="pl-0">
                        <label for="start-hour-vacinacao">Horario de término*</label>
                         <validation-provider
                            #default="{ errors }"
                            name="endTimeVacina"
                            rules="required"
                        >
                        <b-form-timepicker 
                            :hide-header="true"
                            v-model='formContext.form.endTimeVacinacao' 
                            locale='pt-BR' 
                            no-close-button
                            placeholder="18:00"
                        />
                        <small v-if="errors[0]" class="text-danger">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.vacinacao.hora_termino" class="text-danger">
                            {{ formContext.form.errorApi.vacinacao.hora_termino }}
                        </small>
                        </validation-provider>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <div class="errorsForm mt-1">
            <small v-if="formContext.errorSchedule" class="text-danger">
                {{ formContext.errorSchedule }}
            </small>
        </div>
        <div class="buttons mt-4 mb-1 d-flex justify-content-center">
            <b-button class="mr-3" id="campaign-schedule" 
                :disabled="formContext.savingSchedule"
                variant="custom-blue"
                @click="validationForm"
            >
                <span>{{ edit ? 'Salvar Cronograma' : 'Lançar Campanha' }}</span>
                <span v-if="formContext.savingSchedule" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
            </b-button>

            <b-button v-if="!edit" class="" id="campaign-schedule" 
                variant="outline-dark"
                @click="formContext.cancelScheduleCampaign"
            >Voltar</b-button>

            <b-button v-else id="cancel-schedule-campaign" 
                variant="outline-danger"
                @click.prevent="formContext.cancelScheduleCampaign"
            >
                <feather-icon icon="XIcon" class="mr-50"/>
                <span class="align-middle">Cancelar</span>
            </b-button>
        </div>
    </b-modal>
</template>

<script>
import {
  BModal, BButton, BRow, BCol, BForm, BSpinner, BAlert,
  BFormDatepicker, BInputGroupAppend, BFormTimepicker, BFormFile
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import {adhesionContractTypes} from "@/enum/adhesionContractType";

export default {
    components: {
      BFormFile,
        BModal, BButton, BRow, BCol, BForm, BSpinner, BAlert,
        ValidationProvider, ValidationObserver,
        BFormDatepicker, BInputGroupAppend, BFormTimepicker
    },

    props: ['formContext', 'edit'],

    data() {
        return {
            required,
            context: this.$props.formContext,
            errorApiFile: null,
            requiredFile : true,
            customAdhesionContract: false
        }
    },
    mounted() {
      this.customAdhesionContract = this.context.termSelected ? true : false
    },
  methods: {
        validationForm() {
            this.$refs.lancamentoRules.validate().then(success => {
                if (success) {
                    this.formContext.saveScheduledCampaign();
                }
            })
        },
      downloadAdhesionContract(){
        this.$http.get(this.$api.downloadAdhesionContract(this.context.selectedCampaign), {
          responseType: "blob",
          params: {
            tipo: adhesionContractTypes.PERSONALIZADO
          }
        })
            .then(response => {
              const blob = new Blob([response.data], { type: 'application/pdf' })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = response.headers['content-name-file']
              link.click()
              URL.revokeObjectURL(link.href)
            })
            .catch(() => {
              this.$helpers.modalErroServidorPadrao();
            });
      },
      prepareTerm() {
        this.customAdhesionContractDescription = this.selectedCampaign.termo[0].anexo.descricao
      },
      redirectHelp() {
        this.$router.push({ name: 'pages-faq' });
      },
      dropFileAndErrors () {
        this.formContext.form.file = null
      },
    }
}
</script>

<style>

.font-14 {
    font-size: 1rem;
}
button.close {
    display: none;
}

.pointer {
  cursor: pointer;
}

.maxWidthAdhesionContractDescription {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.systemPatternBox{
  height: 180px !important;
  min-width: 320px;
  border: 1px solid #2772C0;
  border-radius: 6px;
  background-color: #E5EEF7;
}

.systemPatternBoxSubtitle{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2772C0;
  width: 377px ;
  margin: 10px auto;
}

.adhesionContractBellow{
  width: fit-content;
  cursor: pointer;
}

.adhesionContractBellowTitle{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.366667px;
  color: #2772C0;
  margin-top: 6px;
}

.titleTerm {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #2772C0;
  margin-top: 6px;
}

@media (min-width: 391px) and (max-width: 501px) {
  .customDownloadButtonResponsive{
    max-width: 250px !important;
  }
  .custom-file {
    width: 280px;
  }
}
@media (max-width: 390px) {
  .customXIconResponsive{
    margin-left: 5px !important;
  }
  .customDownloadButtonResponsive{
    max-width: 150px !important;
  }
}
</style>