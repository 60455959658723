<template>
    <div class="content-wrapper pb-4">
        <PageHeader screenName="Editar Campanha" :linkItems="linkItems"/>

        <ScheduleModal
            :modalShow="scheduleModal.showModal"
            :idCampaign="scheduleModal.idCampaign"
            @closeModalSchedule="closeModals"
            @canceledScheduleCampaign="handleCanceledScheduleCampaign"
            @savedSchedule="openModalSuccess"
        />

        <SucessoModal
            :modalShow="modalSuccess.showModal"
            :typeModal="modalSuccess.typeModal"
            :caseModal="modalSuccess.caseModal"
            @confirmed="redirectToList"
        />

        <ModalShowError
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :infoText="modalError.infoText"
            @confirmed="closeModals"
        />

        <DepartamentoModal
            :modalShow="modalErroDepartamento.showModal"
            :typeModal="modalErroDepartamento.caseModal"
            @confirmed="modalErroDepartamento.action"
        />

        <main class="card p-2">
            <TopButtons
                class="mb-2" 
                :situationCampaign="situationcampaign"
                :idCampaign="idCampanha"
            />

            <b-alert variant="custom-blue" :show="alertCanceledSchedule">
                <h4 class="alert-heading text-custom-blue">Campanha não lançada</h4>
                <div class="alert-body">
                    <span class="text-custom-blue">Clique em ”Lançar Campanha” e finalize o registro das últimas informações para lançá-la.</span>
                </div>
            </b-alert>

            <MinimalInformationCampaignEdit />

            <VaccinePricingAndDoseDistributionCampaignEdit />
        </main>

        <BottomButtons />
    </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import MinimalInformationCampaignEdit from './FormGroups/MinimalInformationCampaignEdit.vue';
import VaccinePricingAndDoseDistributionCampaignEdit from './FormGroups/VaccinePricingAndDoseDistributionCampaignEdit.vue';
import BottomButtons from '@/views/components/custom/ButtonsCampanha/BottomButtons.vue';
import TopButtons from '@/views/components/custom/ButtonsCampanha/TopButtons.vue';
import ScheduleModal from '@/views/custom-pages/Campanhas/Schedule/CampaignScheduleRegister.vue';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue';
import DepartamentoModal from '@/views/components/custom/modals/ModalErrorDepartamento.vue';
import ModalShowError from '@/views/components/custom/modals/ModalShowError.vue';
import HandleErrorMessagesHelper from '@/views/custom-pages/Campanhas/Helpers/HandleErrorMessagesHelper';

import { busEvent } from '@/main';

import payloadService from '../Services/PreparePayloadCampaign';

export default {
    title: 'Editar Campanha',

    components: {
        PageHeader, MinimalInformationCampaignEdit, BottomButtons,
        VaccinePricingAndDoseDistributionCampaignEdit, TopButtons,
        ScheduleModal, SucessoModal, DepartamentoModal, BAlert,
        ModalShowError
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Campanhas',
                    routeName: 'campanha-list' 
                },
                {
                    name: 'Editar Campanha',
                    active: true
                }
            ],
            idCampanha: null,
            minimalDataCampaign: null,
            doseQuantificationData: null,
            vaccinePricingDataCampaign: null,
            doseDistributionRegion: null,
            doseDistributionUnidade: null,
            openScheduleAftersave: false,
            alertCanceledSchedule: false,
            situationcampaign: 'Rascunho',
            scheduleModal: {
                showModal: false,
                idCampaign: null,
                term: null,
            },
            modalSuccess: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'edicao'
            },
            modalError: {
                showModal: false,
                infoText: 'houve um erro ao editar a campanha',
                typeModal: 'erro-campaign-edit',
            },
            modalErroDepartamento: {
                showModal: false,
                caseModal: 'sem-regiao',
                action: this.redirectToDepartamentoView
            },
        }
    },

    mounted() {
        if(this.$route.params.idCampanha) {
            localStorage.setItem('idCampanhaEdit', this.$route.params.idCampanha);
            this.idCampanha = this.$route.params.idCampanha;
        } else {
            this.idCampanha = parseInt(localStorage.getItem('idCampanhaEdit'));
        }

        this.loadDataCampaign();
        this.listenEventsDatacampaign();

        busEvent.$on('saveCampanha', (type) => {
            this.handleSaveCampanha(type);
        });
        busEvent.$on('noRegionDistribution', () => {
            this.handleNoRegion();
        });
        busEvent.$on('noUnitDistribution', () => {
            this.handleNoUnit();
        });
    },

    beforeDestroy() {
        busEvent.$off();
    },

    methods: {
        listenEventsDatacampaign() {
            busEvent.$on('minimalDataCampaign', (minimalDataCampaign) => {
                this.minimalDataCampaign = minimalDataCampaign;
            });
            busEvent.$on('doseQuantificationDataCampaign', (doseQuantificationData) => {
                this.doseQuantificationData = doseQuantificationData;
            });
            busEvent.$on('vaccinePricingDataCampaign', (vaccinePricingDataCampaign) => {
                this.vaccinePricingDataCampaign = vaccinePricingDataCampaign;
            });
            busEvent.$on('doseDistributionRegion', (doseDistributionRegion) => {
                this.doseDistributionRegion = doseDistributionRegion;
            });
            busEvent.$on('doseDistributionUnidade', (doseDistributionUnidade) => {
                this.doseDistributionUnidade = doseDistributionUnidade;
            });
        },

        async loadDataCampaign() {
            if(!this.idCampanha) { this.redirectToShow() } 

            const parametizedRouteCampanha = this.$api.getShowAndEditCampanha(this.idCampanha);
            const campanha = await this.$http.get(parametizedRouteCampanha);
            busEvent.$emit('situationCampaign', campanha.data.situacao);
            this.situationcampaign = campanha.data.situacao;
            this.scheduleModal.term = campanha.data.termo
            if(this.situationcampaign == 'Cancelada' || this.situationcampaign == 'Concluída') {
                this.$router.push({ name: 'campanha-list' });
            }

            const incentives = await this.$http.get(this.$api.getIncentiveDisease(campanha.data.id_doenca));
            
            busEvent.$emit('dataCampanha', {
                campanha: campanha.data,
                incentives: incentives.data
            });
        },

        handleSaveCampanha(type) {
            busEvent.$emit('savingCampaign', true);
            if(type == 'schedule') {
                this.openScheduleAftersave = true;
            } else {
                this.openScheduleAftersave = false;
            }
            this.saveCampaign();
        },

        redirectToShow() {
            this.$router.push(
                {
                    name: 'campanha-list',
                }
            );
        },

        saveCampaign() {
            if(
                this.minimalDataCampaign &&
                this.doseQuantificationData &&
                this.vaccinePricingDataCampaign &&
                this.doseDistributionRegion &&
                this.doseDistributionUnidade
            ) {
                const payload = {
                    descricao: this.minimalDataCampaign.descricao,
                    permite_adesao_parcial: this.minimalDataCampaign.permite_adesao_parcial,
                    vacinas: payloadService.makePayload({
                        minimalInfo: this.minimalDataCampaign,
                        quantification: this.doseQuantificationData,
                        pricging: this.vaccinePricingDataCampaign,
                        distributionRegion: this.doseDistributionRegion,
                        distributionUnidade: this.doseDistributionUnidade
                    })
                }
                this.$http.put(this.$api.getShowAndEditCampanha(this.idCampanha), payload).then(() => {
                    busEvent.$emit('savingCampaign', false);
                    this.openSchedule();
                }).catch(({ response }) => {
                    busEvent.$emit('savingCampaign', false);

                    if(response.status == 422) {
                        this.modalError.infoText = HandleErrorMessagesHelper.extractErrorMessages(response.data.errors);
                        this.modalError.showModal = true;
                    } else {
                        this.modalError.infoText = response.data.errors;
                        this.modalError.showModal = true;
                    }

                    this.minimalDataCampaign = null;
                    this.doseQuantificationData = null;
                    this.vaccinePricingDataCampaign = null;
                    this.doseDistributionRegion = null;
                    this.doseDistributionUnidade = null;
                })
            } else {
                setTimeout(() => {
                    this.saveCampaign();
                }, 100);
            }
        },

        openSchedule() {
            if(this.openScheduleAftersave) {
                this.scheduleModal.idCampaign = this.idCampanha;
                this.scheduleModal.showModal = true;
                this.alertCanceledSchedule = false;
            } else { this.openModalSuccess(); }
        },

        closeModals() {
            this.scheduleModal.showModal = false;
            this.modalError.showModal = false;
        },

        handleCanceledScheduleCampaign() {
            this.scheduleModal.showModal = false;
            this.alertCanceledSchedule = true;
        },

        openModalSuccess() {
            this.modalSuccess.showModal = true;
        },

        redirectToList() {
            this.$router.push({ name: 'campanha-list'});

            this.modalSuccess.showModal = false;
        },

        redirectToViewDepartament() {
            this.$router.push({ name: 'departamento-view' });
            this.modalErroDepartamento.showModal = false;
        },

        handleNoRegion(){
            this.modalErroDepartamento.caseModal = 'sem-regiao'
            this.modalErroDepartamento.action = this.redirectToDepartamentoView
            this.modalErroDepartamento.showModal = true
        },

        handleNoUnit(){
            this.modalErroDepartamento.caseModal = 'sem-unidade'
            this.modalErroDepartamento.action = this.redirectToUnidadeList
            this.modalErroDepartamento.showModal = true
        },

        redirectToDepartamentoView() {
            this.$router.push({ name: 'departamento-view' });
            this.modalErroDepartamento.showModal = false;
        },

        redirectToUnidadeList() {
            this.$router.push({ name: 'unidade-list' });
            this.modalErroDepartamento.showModal = false;
        },
    }
}
</script>
