<template>
    <section class="formHeader">
        <ScheduleModalEdit
            :modalShow="scheduleEditModal.showModal"
            :idCampaign="scheduleEditModal.idCampaign"
            :situationCampaign="scheduleEditModal.situationCampaign"
            :term="scheduleEditModal.term"
            @closeModalSchedule="closeModalSchedule"
            @canceledScheduleCampaign="closeModalSchedule"
            @savedSchedule="openModalSuccess"
        />

        <SucessoModal
            :modalShow="modalSuccess.showModal"
            :typeModal="modalSuccess.typeModal"
            :caseModal="modalSuccess.caseModal"
            @confirmed="closeModalSuccess"
        />

        <div>
            <b-button 
                v-if="showButtonCronograma"
                class="mr-2 space-button" 
                id="campaign-schedule"
                :disabled="disabledScheduleCampaign || (disableSaving || savingInfosCampanha)"
                variant="outline-custom-blue"
                @click="saveInfosCampanha()"
            >
                <span>Lançar Campanha</span> 
                <span v-if="editingCampaign" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
            </b-button>

            <b-button 
                v-if="showButtonEditCronograma"
                class="mr-2 space-button" 
                id="campaign-schedule-edit" 
                variant="custom-blue"
                @click="openModalScheduleEdit()"
            ><span>Editar Cronograma</span></b-button>

            <SituationBadge />
        </div>
    </section>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import SituationBadge from '@/views/components/custom/SituationCampagin/SituationBadges.vue';
import ScheduleModalEdit from '@/views/custom-pages/Campanhas/Schedule/CampaignScheduleEdit.vue';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue';
import { getInfoUserDepartamento } from "@core/utils/store/getStore";

import { busEvent } from '@/main';

export default {
    components: {
        BButton, SituationBadge, ScheduleModalEdit,
        SucessoModal
    },

    props: {
        situationCampaign: {
            type: String,
        },
        idCampaign: {
            type: Number
        }
    },

    data() {
        return {
            scheduleEditModal: {
                showModal: false,
                idCampaign: null,
                situationCampaign: null,
                term: null,
            },
            modalSuccess: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'edit-schedule'
            },
            disabledScheduleCampaign: true,
            amountVaccines: 0,
            vaccinesCanSchedule: [],
            editingCampaign: false,
            disableSaving: false,
            savingInfosCampanha: false,
            saveSchedulePrice: false,
            saveScheduleDistributionRegion: false,
            saveScheduleDistributionUnidade: false,
            showButtonCronograma: false,
            showButtonEditCronograma: false,
            situationCampaignSelected: this.$props.situationCampaign ? this.$props.situationCampaign : 'Rascunho',
            idCampaignSelected: this.$props.idCampaign ? this.$props.idCampaign : null,
        }
    },

    mounted() {
        busEvent.$on('disableButtonSave', (numberDoseError) => {
            this.disableSaving = numberDoseError.error;
        });

        busEvent.$on('savingCampaign', (saving) => {
            this.savingInfosCampanha = saving;
            this.editingCampaign = saving;
        });

        busEvent.$on('vaccinesEdited', this.listenerAmountVaccines);
        busEvent.$on('AllVaccinesCadastro', this.listenerAmountVaccines);
        busEvent.$on('dataCampanha', (dataCampanha) => {
          this.scheduleEditModal.term = dataCampanha.campanha.termo;
        })
        this.checkIfEditCronograma();
        this.eventsSaveCronograma()
    },

    methods: {
        saveInfosCampanha() {
            this.editingCampaign = true;
            busEvent.$emit('saveCampanha', 'schedule');
        },

        eventsSaveCronograma() {
            busEvent.$on('canSaveSchedulePrice', (dataPrice) => {
                this.saveSchedulePrice = dataPrice[0];
                this.checkIfCanSaveCronograma(dataPrice[1]);
            });
            busEvent.$on('canSaveScheduleDistributionRegion', (dataRegion) => {
                this.saveScheduleDistributionRegion = dataRegion[0];
                this.checkIfCanSaveCronograma(dataRegion[1]);
            });
            busEvent.$on('canSaveScheduleDistributionUnidade', (dataUnidade) => {
                this.saveScheduleDistributionUnidade = dataUnidade[0];
                this.checkIfCanSaveCronograma(dataUnidade[1]);
            });
        },

        checkIfCanSaveCronograma(idVaccine) {
            if(!getInfoUserDepartamento('divisao_por_regiao')) {
                this.saveScheduleDistributionRegion = true;
            }

            if(this.saveSchedulePrice && this.saveScheduleDistributionRegion && this.saveScheduleDistributionUnidade){                     
                if(idVaccine && !this.vaccinesCanSchedule.includes(idVaccine)) {
                    this.vaccinesCanSchedule.push(idVaccine);
                }
            } else {
                this.vaccinesCanSchedule = this.vaccinesCanSchedule.filter(vaccine => vaccine != idVaccine);
            }
            
            if(this.vaccinesCanSchedule.length == this.amountVaccines) {
                this.disabledScheduleCampaign = false; 
                return;
            } 

            this.disabledScheduleCampaign = true; 
        },

        checkIfEditCronograma() {
            if(this.situationCampaignSelected == 'Rascunho') {
                this.showButtonEditCronograma = false;
                this.showButtonCronograma = true;
            } else if(
                this.situationCampaignSelected == 'Agendada' || 
                this.situationCampaignSelected == 'Em andamento' || 
                this.situationCampaignSelected == 'Concluída'
            ) { 
                this.showButtonEditCronograma = true;
                this.showButtonCronograma = false;
            }
        },

        listenerAmountVaccines(vaccines) {
            this.amountVaccines = vaccines.length;
        },

        openModalScheduleEdit() {
            this.scheduleEditModal.situationCampaign = this.situationCampaignSelected;
            this.scheduleEditModal.idCampaign = this.idCampaignSelected;

            setTimeout(() => {this.scheduleEditModal.showModal = true;}, 50); 
        },

        closeModalSchedule() {
            this.scheduleEditModal.showModal = false;
        },

        openModalSuccess() {
            this.scheduleEditModal.showModal = false;
            this.modalSuccess.showModal = true;
        },

        closeModalSuccess() {
            this.modalSuccess.showModal = false;
            this.$router.push({ name: 'campanha-list'});
        },
    },

    watch: {
        situationCampaign(value) {
            this.situationCampaignSelected = value;

            this.checkIfEditCronograma();
        },

        idCampaign(value) {
            this.idCampaignSelected = value;
        }
    }
}
</script>
