<template>
    <BaseFormSchedule 
        :formContext="formContext"
        :edit="true"
    />
</template>

<script>
import handleErrorsService from '@/views/custom-pages/Campanhas/RegisterCampanha/Forms/Services/HandleErrors';
import BaseFormSchedule from './BaseFormSchedule.vue';
import scheduleService from '../Services/ScheduleCampaignService';

import moment from 'moment';

export default {
    components: {
        BaseFormSchedule
    },

    props: {
        modalShow: {
            required: true,
            type: Boolean,
        },
        idCampaign: {
            required: true,
            type: Number|null,
        },
        situationCampaign: {
            required: true,
            type: String|null
        },
        descriptionCampaign: {
            required: false,
            type: String|null
        },
        term: {
          required: false,
          type: Array|null,
        },
    },
    data() {
        return {
            formContext: this,
            showScheduleModal: this.$props.modalShow,
            selectedCampaign: this.$props.idCampaign,
            situationCampaignSelected: this.$props.situationCampaign,
            descriptionCampaignSelected: this.$props.descriptionCampaign,
            termSelected: this.$props.term,
            customAdhesionContractSelected: this.$props.customAdhesionContract,
            disabledFieldEdit: {
                adhesionStartDate: false,
                adhesionEndDate: false,

                vaccinationStartDate: false
            },

            form: {
                startDateAdesao: null,
                endDateAdesao: null,
                startTimeAdesao: null,
                endTimeAdesao: null,
                startDateVacinacao: null,
                endDateVacinacao: null,
                startTimeVacinacao: null,
                endTimeVacinacao: null,
                file: this.$props.term ? this.$props.term[0] : null,
                errorApi: {
                    vacinacao: {
                        data_inicio: '',
                        hora_inicio: '',
                        data_termino: '',
                        hora_termino: '',
                    },
                    adesao: {
                        data_inicio: '',
                        hora_inicio: '',
                        data_termino: '',
                        hora_termino: '',
                    },
                    error: null
                }
            },
            errorSchedule: null,
            savingSchedule: false,
            loadingSchedule: true
        }
    },
  methods: {
        cancelScheduleCampaign() {
            this.$emit('canceledScheduleCampaign');
            handleErrorsService.cleanErrors(this.form.errorApi);
        },

        saveScheduledCampaign() {
          const dataSchedule = scheduleService.prepareDataSchedule(this.form, this.situationCampaignSelected, true);

          if(!dataSchedule) { return; }

            this.savingSchedule = true;
            this.errorSchedule = null;
            this.$http.post(this.$api.campanhaCronograma(this.selectedCampaign), dataSchedule).then(() => {
                this.$emit('savedSchedule');
                this.savingSchedule = false;
                handleErrorsService.cleanErrors(this.form.errorApi);
            }).catch(({ response: { data } }) => {
                handleErrorsService.handleErrosLancamento(data, this.form.errorApi);
                this.savingSchedule = false;
            });
        },

        blockFieldsBySituationCampaign() {
            this.disabledFieldEdit.adhesionStartDate = false;
            this.disabledFieldEdit.adhesionEndDate = false;
            this.disabledFieldEdit.vaccinationStartDate = false;
            this.disabledFieldEdit.adhesionStartDate = false;

            if(this.situationCampaignSelected == 'Em andamento') {
                this.disabledFieldEdit.adhesionStartDate = true;
            } 

            if(this.situationCampaignSelected == 'Concluída') {
                this.disabledFieldEdit.adhesionStartDate = true;
                this.disabledFieldEdit.adhesionEndDate = true;
                this.disabledFieldEdit.vaccinationStartDate = true;
            }   
        },

        async fillScheduleForm() {
            this.loadingSchedule = true;
            
            const { data } = await this.$http.get(this.$api.campanhaCronograma(this.selectedCampaign));

            this.loadingSchedule = false;

            const sheduleAdhesion = data.find(schedule => schedule.tipo == 'Adesão');
            const sheduleVaccination = data.find(schedule => schedule.tipo == 'Vacinação');
            this.form.startDateAdesao = moment(sheduleAdhesion.inicio).format('YYYY-MM-DD');
            this.form.endDateAdesao = moment(sheduleAdhesion.termino).format('YYYY-MM-DD');
            this.form.startTimeAdesao = moment(sheduleAdhesion.inicio).format('HH:mm');
            this.form.endTimeAdesao = moment(sheduleAdhesion.termino).format('HH:mm');

            this.form.startDateVacinacao = moment(sheduleVaccination.inicio).format('YYYY-MM-DD');
            this.form.endDateVacinacao = moment(sheduleVaccination.termino).format('YYYY-MM-DD'),
            this.form.startTimeVacinacao = moment(sheduleVaccination.inicio).format('HH:mm');
            this.form.endTimeVacinacao = moment(sheduleVaccination.termino).format('HH:mm');
        }
    },

    watch: {
        modalShow(newValue) {
            this.blockFieldsBySituationCampaign();
            this.showScheduleModal = newValue;
            this.fillScheduleForm();
        },

        idCampaign(idCampaign) {
            this.selectedCampaign = idCampaign;
            handleErrorsService.cleanErrors(this.form.errorApi);
        },

        situationCampaign(situation) {
            this.situationCampaignSelected = situation;
        },

        descriptionCampaign(description) {
            this.descriptionCampaignSelected = description;
        },

        term(term) {
          this.termSelected = term
          this.form.file = null
        },

    }
}
</script>
