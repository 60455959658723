import moment from 'moment';
import 'moment-timezone';

export default {
    prepareDataSchedule(formSchedule, situation = '', edit= false) {
        const adesaoStartDate = moment.tz(new Date(formSchedule.startDateAdesao+' '+formSchedule.startTimeAdesao), 'America/Sao_Paulo');
        const adesaoFinishDate = moment.tz(new Date(formSchedule.endDateAdesao+' '+formSchedule.endTimeAdesao), 'America/Sao_Paulo');

        const vacinacaoStartDate = moment.tz(new Date(formSchedule.startDateVacinacao+' '+formSchedule.startTimeVacinacao), 'America/Sao_Paulo');
        const vacinacaoFinishDate = moment.tz(new Date(formSchedule.endDateVacinacao+' '+formSchedule.endTimeVacinacao), 'America/Sao_Paulo');

        let formData = new FormData();
        formData.append('adesao[data_inicio]', adesaoStartDate.format('YYYY-MM-DD'));
        formData.append('adesao[hora_inicio]', adesaoStartDate.format('HH:mm:ss'));
        formData.append('adesao[data_termino]', adesaoFinishDate.format('YYYY-MM-DD'));
        formData.append('adesao[hora_termino]', adesaoFinishDate.format('HH:mm:ss'));

        formData.append('vacinacao[data_inicio]', vacinacaoStartDate.format('YYYY-MM-DD'));
        formData.append('vacinacao[hora_inicio]', vacinacaoStartDate.format('HH:mm:ss'));
        formData.append('vacinacao[data_termino]', vacinacaoFinishDate.format('YYYY-MM-DD'));
        formData.append('vacinacao[hora_termino]', vacinacaoFinishDate.format('HH:mm:ss'));

        formData.append('situacao', situation);
        if(formSchedule.file != null) {
            formData.append('termo', formSchedule.file);
        }
        
        if(edit) {
            formData.append('_method', 'PUT')
        }

        return formData

    }
}
